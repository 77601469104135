import * as React from "react";
import { MobileNavigationMenu } from "./mobile-navigation-menu";
import { MobileNavigationInitialStateProvider } from "./providers/mobile-navigation-initial-state-provider";

interface AppProps {
  mountElement: Element;
}

export const App: React.FunctionComponent<AppProps> = (props) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);
  React.useEffect(() => {
    const mobileNavHamburgerBtnSelect = document.querySelector(
      ".hamburger.hamburger--squeeze, .hamburger.hamburger--squeeze.is-active",
    );

    const mobileNavLinkSelect = document.querySelectorAll(
      ".drawer-inner a, .drawer-inner li",
    );

    const toggleNavMenu = (event: any) => {
      setIsMobileNavOpen(!isMobileNavOpen);
      event.preventDefault();
      event.stopPropagation();
    };

    const handleNavLinkClick = (event: any) => {
      setIsMobileNavOpen(false);
    };

    mobileNavHamburgerBtnSelect?.addEventListener("click", toggleNavMenu);
    mobileNavLinkSelect.forEach((link) =>
      link.addEventListener("click", handleNavLinkClick),
    );

    // to prevent pull to refresh
    // const elements = document.querySelectorAll(".mobile-nav-main");
    // elements.forEach(function (element) {
    //   element.addEventListener(
    //     "touchstart",
    //     function (event) {
    //       event.preventDefault();
    //     },
    //     { passive: false },
    //   );
    // });

    return () => {
      mobileNavHamburgerBtnSelect?.removeEventListener("click", toggleNavMenu);
      mobileNavLinkSelect.forEach((link) =>
        link.removeEventListener("click", handleNavLinkClick),
      );
    };
  }, [isMobileNavOpen]);

  return (
    <div>
      <MobileNavigationInitialStateProvider mountElement={props.mountElement}>
        <MobileNavigationMenu isMobileNavOpen={isMobileNavOpen} />
      </MobileNavigationInitialStateProvider>
    </div>
  );
};
