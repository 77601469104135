import * as React from "react";
import { useMobileNavigationInitialState } from "../providers/mobile-navigation-initial-state-provider";

export interface MobileNavigationMenuProps {
  isMobileNavOpen?: boolean;
}

interface MenuItem {
  label: string;
  href: string;
}

export const MobileNavigationMenu = ({
  isMobileNavOpen,
}: MobileNavigationMenuProps) => {
  const { initialState } = useMobileNavigationInitialState();
  const menuItems: MenuItem[] = initialState?.menu_items || [];
  const isUserLoggedIn = initialState?.isUserLoggedIn;

  return (
    <div className="mobile-nav-main">
      <div className={`drawer ${isMobileNavOpen ? "open" : ""}`}>
        <div className="drawer-inner">
          <div className="mobile-nav-info-container">
            <div className="mobile-nav-info-card">
              <a href="/sales/guest/form">
                <div className="mobile-nav-track-order-icon"></div>
                <div>Track Order</div>
              </a>
            </div>
            <div className="mobile-nav-info-card">
              <a href="/support/home">
                <div className="mobile-nav-help-icon"></div>
                <div>Help Centre</div>
              </a>
            </div>
            <div className="mobile-nav-info-card">
              <a href="/locator">
                <div className="mobile-nav-locator-icon"></div>
                <div>Store Locator</div>
              </a>
            </div>
          </div>
          <div className="mobile-nav-gift-card-main">
            <a href="/gift-cards">
              <div className="mobile-nav-gift-card-text">
                SHOP OUR E-GIFT CARDS
              </div>
            </a>
          </div>
          <ul className="drawer-menu">
            {menuItems.map((item, index) => {
              const isActive = window.location.pathname === item.href;
              return (
                <li
                  key={index}
                  className={`drawer-item ${index < 2 ? "header" : ""} ${isActive ? "active-menu" : ""}`}
                  onClick={() => (window.location.href = item.href)}
                >
                  <a href={item.href}>
                    {item.label} <span className="drawer-item-arrow"></span>
                  </a>
                </li>
              );
            })}
          </ul>
          {!isUserLoggedIn ? (
            <div className="drawer-footer">
              <a href="/customer/account">
                <button className="drawer-footer-btn">
                  <span className="mobile-nav-sign-icon"></span>SIGN IN OR JOIN
                  REWARDS
                </button>
              </a>
            </div>
          ) : (
            <div className="drawer-footer">
              <button className="drawer-footer-btn-hide"></button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
